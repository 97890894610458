import React from "react";
import AppcheckBase from "@src/layouts/AppcheckBase";

function AppcheckSchoolLeaderSignup() {
  return (
    <AppcheckBase>
      <a
        id="app-trigger"
        href="https://www.classdojo.com/"
        data-app="https://www.classdojo.com/"
        data-app-ios="classdojo://"
        data-app-android="https://bnc.lt/open-or-download-app"
        data-store-android="com.classdojo.android"
        data-store-ios="552602056"
        style={{ color: "#fff" }}
      >
        ClassDojo App
      </a>
    </AppcheckBase>
  );
}

export default AppcheckSchoolLeaderSignup;
